import {
    OPEN,
    CLOSE,
} from './action-types'

const modal = {
    open: (
        component = null,
        props = {},
        options = {},
    ) => {
        return {
            type: OPEN,
            payload: {
                component,
                props,
                options: {
                    clickToClose: true,
                    closeButton: true,
                    width: 320,
                    ...options,
                },
            },
        }
    },
    close: () => {
        return {
            type: CLOSE,
            payload: null,
        }
    },
}

export default modal
