import React   from "react";
import { Link, NavLink } from "react-router-dom";

import "./ButtonLink.css";



const ButtonLink = ({ to, children }) => {
  return (
    // <button>
    <NavLink
      className={({ isActive }) => (isActive ? "active-link" : "default")}
      to={to}
    >
      {children}
    </NavLink>
    // </button>
  );
};

export default ButtonLink;
