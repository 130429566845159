import { lazy } from "react";

const routes = [
  {
    path: "/dashboard/home",
    auth: true,
    exact: true,
    component: lazy(() => import("./pages/home")),
  },
  {
    path: "/dashboard/accounts",
    auth: true,
    exact: true,
    component: lazy(() => import("./pages/bank")),
  },
  {
    path: "/dashboard/transactions",
    auth: true,
    exact: true,
    component: lazy(() => import("./pages/transactions")),
  },
  {
    path: "/dashboard/reports",
    auth: true,
    exact: true,
    component: lazy(() => import("./pages/reports")),
  },
  {
    path: "/dashboard/settings",
    auth: true,
    exact: true,
    component: lazy(() => import("./pages/settings")),
  },
];

export default routes
