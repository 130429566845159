import React, { useState, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { mixins, colors } from '../../vars'

import Icon from './Icon'

const Calendar = ({
    range = false,
    dates = [],
    onSelect = () => {},
    firstDayOfWeek = 'sunday',
    weekdayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    minDate,
    maxDate,
}) => {
    const [preview, setPreview] = useState(null)
    const [view, setView] = useState('days')

    const page = useMemo(() => {
        if (preview) return preview
        const date = dates?.length > 0 ? dates[0] : new Date()
        return { year: date.getFullYear(), month: date.getMonth() }
    }, [dates, preview])

    const monthName = useMemo(() => {
        let { month } = page
        const y = Math.abs(Math.floor(month / 12))
        if (month < 0) month += 12 * y
        if (month > 11) month -= 12 * y
        return monthNames[month]
    }, [page, monthNames])

    const yearName = useMemo(() => {
        const { year, month } = page
        return new Date(year, month, 1).getFullYear()
    }, [page])

    const changePreview = useCallback(d => {
        let newPreview = preview ? { ...preview } : { ...page }
        if (view === 'days') newPreview.month += d
        if (view === 'months') newPreview.year += d
        if (view === 'years') newPreview.year += d * 12
        setPreview(newPreview)
    }, [preview, page, view])

    const toggleView = useCallback(() => {
        setView(view => view === 'days' ? 'months' : view === 'months' ? 'years' : view)
    }, [])

    const days = useMemo(() => {
        if (view !== 'days') return null

        const { year, month } = page

        const firstDay = { sunday: 0, monday: 1 }[firstDayOfWeek]
        const start = dates[0] ? moment(dates[0]).startOf('day').format() : null
        const end = dates[1] ? moment(dates[1]).startOf('day').format() : null
        const min = minDate ? moment(minDate).startOf('day') : null
        const max = maxDate ? moment(maxDate).startOf('day') : null

        const pageDate = moment(new Date(year, month, 1))

        let dayOffset = 1 - pageDate.day() + firstDay
        if (dayOffset > 1) dayOffset -= 7

        const isActive = date => date.isSame(start, 'day') || date.isSame(end, 'day')
        const isBetween = date => start && end && date.isAfter(start) && date.isBefore(end)
        const isStart = date => end && date.isSame(start, 'day')
        const isEnd = date => start && date.isSame(end, 'day')
        const isOutside = date => date.month() !== pageDate.month()
        const isDisabled = date => {
            if (min && min.isAfter(date)) return true
            if (max && max.isBefore(date)) return true
            if (range && start && !end && start.isAfter(date)) return true
            return false
        }

        return (
            <>
                {Array.from({ length: 7 }, (_, idx) => {
                    let day = idx + firstDay
                    if (day < 0) day += 7
                    if (day > 6) day -= 7
                    return (
                        <Weekday key={idx}>
                            {weekdayNames[day]}
                        </Weekday>
                    )
                })}
                {Array.from({ length: 42 }, (_, idx) => {
                    const date = moment(new Date(year, month, idx + dayOffset)).startOf('day')
                    return (
                        <Day
                            isActive={isActive(date)}
                            isDisabled={isDisabled(date)}
                            isBetween={isBetween(date)}
                            isStart={isStart(date)}
                            isEnd={isEnd(date)}
                            isOutside={isOutside(date)}
                            key={date.toString()}
                            onClick={() => onSelect(date.toDate())}
                        >
                            {date.date()}
                        </Day>
                    )
                })}
            </>
        )
    }, [view, dates, page, minDate, maxDate, range, firstDayOfWeek, onSelect])

    const months = useMemo(() => {
        if (view !== 'months') return null

        const { year, month } = page

        const selectedMonth = dates[0] && moment(dates[0]).month()
        const selectedYear = dates[0] && moment(dates[0]).year()
        const currentYear = moment(new Date(year, month, 1)).year()

        const minMonth = minDate ? moment(minDate).month() : null
        const minYear = minDate ? moment(minDate).year() : null
        const maxMonth = maxDate ? moment(maxDate).month() : null
        const maxYear = maxDate ? moment(maxDate).year() : null

        const isDateBeforeMin = month => {
            if (!minDate) return false
            if (minYear > currentYear) return true
            if (minYear === currentYear && minMonth > month) return true
            return false
        }

        const isDateAfterMax = month => {
            if (!maxDate) return false
            if (maxYear < currentYear) return true
            if (maxYear === currentYear && maxMonth < month) return true
            return false
        }

        const isDateBeforeSelected = month => {
            if (!range) return false
            if (dates[0] && !dates[1]) {
                if (moment(dates[0]).year() > currentYear) return true
                if (
                    moment(dates[0]).year() === currentYear &&
                    moment(dates[0]).month() > month
                ) return true
            }
            return false
        }

        const isDisabled = month => {
            if (isDateBeforeMin(month)) return true
            if (isDateAfterMax(month)) return true
            if (isDateBeforeSelected(month)) return true
            return false
        }

        const isActive = gridMonth => gridMonth === selectedMonth && selectedYear === currentYear

        const selectMonth = date => {
            setPreview({ year: date.getFullYear(), month: date.getMonth() })
            setView('days')
        }

        return Array.from({ length: 12 }).map((_, idx) => {
            const date = moment(new Date(currentYear, idx, 1)).startOf('day')
            const month = date.month()
            return (
                <Month
                    isActive={isActive(month)}
                    isDisabled={isDisabled(month)}
                    key={date.toString()}
                    onClick={() => selectMonth(date.toDate())}
                >
                    {monthNames[month]}
                </Month>
            )
        })
    }, [view, dates, page, minDate, maxDate, range, monthNames])

    const years = useMemo(() => {
        if (view !== 'years') return null

        const { year, month } = page

        const selectedYear = dates[0] && moment(dates[0]).year()
        const minYear = minDate ? moment(minDate).year() : null
        const maxYear = maxDate ? moment(maxDate).year() : null

        const isDisabled = year => {
            if (minYear && minYear > year) return true
            if (maxYear && maxYear < year) return true
            if (!range) return false
            if (dates[0] && !dates[1] && (moment(dates[0]) > year).year()) return true
            return false
        }

        const selectYear = date => {
            setPreview({ year: date.getFullYear(), month: date.getMonth() })
            setView('months')
        }

        return Array.from({ length: 12 }).map((_, idx) => {
            const date = moment(new Date(year - (year % 12) + idx, month, 1))
            const gridYear = date.year()
            return (
                <Year
                    isActive={gridYear === selectedYear}
                    isDisabled={isDisabled(gridYear)}
                    key={date.toString()}
                    onClick={() => selectYear(date.toDate())}
                >
                    {date.format('YYYY')}
                </Year>
            )
        })
    }, [view, dates, page, minDate, maxDate, range])

    return (
        <Container>
            <Header
                toggleView={toggleView}
                changePreview={changePreview}
                month={view === 'days' ? monthName : null}
                year={yearName}
            />
            <Page>
                {{ days, months, years }[view]}
            </Page>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    min-width: 270px;
    width: 320px;
    margin: -10px 0;
`

const Page = styled.div`
    display: flex;
    flex-flow: row wrap;
    background-color: ${colors.white};
    height: 230px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 10px 10px;
`

const HeaderButton = styled.button`
    flex: 0 0 auto;
    color: ${colors.gray};
    font-size: 16px;
    position: relative;

    &:before {
        ${mixins.inset('-15px')}
        position: absolute;
        content: '';
    }

    &:hover {
        opacity: 0.5;
    }
`

const HeaderTitle = styled.button`
    flex: 0 0 auto;
    font-size: 16px;
    text-align: center;
    color: ${colors.gray};
    margin: 0 auto;
`

const Header = styled.div.attrs(p => ({
    children: (
        <>
            <HeaderButton onClick={() => p.changePreview(-1)}>
                <Icon name="chevron-left" color="gray" size="14" />
            </HeaderButton>
            <HeaderTitle onClick={p.toggleView}>
                {p.month && <span>{p.month} </span>}
                {p.year}
            </HeaderTitle>
            <HeaderButton onClick={() => p.changePreview(1)}>
                <Icon name="chevron-right" color="gray" size="14" />
            </HeaderButton>
        </>
    ),
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background: ${colors.background};
    height: 50px;
`

const Weekday =  styled.div`
    flex: 0 0 auto;
    width: 14.2857%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.lightGray};
    font-size: 14px;
`

const Day = styled.div`
    font-size: 14px;
    color: ${colors.gray};
    cursor: pointer;
    position: relative;
    flex: 0 0 auto;
    width: 14.2857%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: ${colors.gold};
    }

    &:before {
        ${mixins.inset()}
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 100%;
        background-color: ${colors.rgba(colors.gold, 0.5)};
        z-index: -2;
    }

    &:after {
        ${mixins.inset()}
        position: absolute;
        top: 0;
        left: calc(50% - 15px);
        height: 30px;
        width: 30px;
        background-color: ${colors.gold};
        z-index: -1;
    }

    ${p => p.isDisabled && css`
        color: #ccc;
        pointer-events: none;
    `}

    ${p => p.isBetween && css`
        color: ${colors.gray};

        &:before {
            content: '';
        }
    `}

    ${p => p.isActive && css`
        color: ${colors.white};

        &:hover {
            color: ${colors.white};
        }

        &:after {
            content: '';
        }

        ${p => p.isEnd && css`
            &:before {
                content: '';
                width: 50%;
                left: 0;
            }
        `}

        ${p => p.isStart && css`
            &:before {
                content: '';
                width: 50%;
                left: 50%;
            }
        `}

        ${p => p.isStart && p.isEnd && css`
            &:before {
                display: none;
            }
        `}
    `}

    ${p => p.isOutside && css`
        color: #ccc7 !important;
        pointer-events: none !important;
        font-weight: 300;

        &:before,
        &:after {
            display: none;
        }
    `}
`

const Month = styled.div`
    flex: 0 0 auto;
    width: 33.333%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${colors.gray};
    cursor: pointer;

    &:hover {
      color: ${colors.gold};
    }

    ${p => p.isActive && css`
        background-color: ${colors.gold};
        color: ${colors.white} !important;
    `}

    ${p => p.isDisabled && css`
        opacity: 0.2;
        font-weight: 300;
        pointer-events: none;
    `}
`

const Year = styled.div`
    flex: 0 0 auto;
    width: 33.333%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${colors.gray};
    cursor: pointer;

    &:hover {
      color: ${colors.gold};
    }

    ${p => p.isActive && css`
        background-color: ${colors.gold};
        color: ${colors.white} !important;
    `}

    ${p => p.isDisabled && css`
        opacity: 0.2;
        font-weight: 300;
        pointer-events: none;
    `}
`

export default Calendar
