import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../common/Layout'

const PublicLayout = ({ children }) => {
    return (
        <Layout>
            {children}
        </Layout>
    )
}

PublicLayout.dispatch = 'Public Layout'
PublicLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default PublicLayout
