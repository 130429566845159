import React, { useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { isDate } from 'lodash'

import UIDropdown from './Dropdown'
import Input from './Input'
import Icon from './Icon'
import Calendar from './Calendar'

const DatePicker = ({
    value,
    minDate,
    maxDate,
    onChange = () => {},
    placeholder,
    border,
    align,
    size,
    width,
    className,
    style,
    disabled = false,
}) => {
    const dropdownRef = useRef()
    const [focused, setFocused] = useState(false)

    const handleSelect = useCallback(date => {
        onChange(date)
        dropdownRef.current.close()
    }, [])

    return (
        <Dropdown
            trigger={(
                <>
                    <Input
                        size={size}
                        value={isDate(value) ? moment(value).format('MMM D, YYYY') : ''}
                        align={align}
                        onChange={() => {}}
                        placeholder={placeholder}
                        border={border}
                        disabled
                        focused={focused}
                        className="select-input"
                    />
                    <CaretDown className="select-caret" />
                </>
            )}
            size={size}
            ref={dropdownRef}
            width={width}
            maxDropdownHeight={1000}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={style}
            className={className}
            disabled={disabled}
        >
            <Calendar
                dates={isDate(value)  ? [value] : []}
                onSelect={handleSelect}
                minDate={minDate}
                maxDate={maxDate}
            />
        </Dropdown>
    )
}

const caretPos = {
    top: { s: 7, m: 9, l: 12 },
    right: { s: 5, m: 7, l: 9 },
    padding: { s: 14, m: 20, l: 26 },
}

const Dropdown = styled(UIDropdown)`
    .select-caret {
        top: ${p => caretPos.top[p.size] || caretPos.top.m}px;
        right: ${p => caretPos.right[p.size] || caretPos.right.m}px;
    }

    .select-input {
        padding-right: ${p => caretPos.padding[p.size] || caretPos.padding.m}px;
    }

    &.opened .select-caret {
        transform: rotate(180deg);
    }
    ${p => p.disabled && 'pointer-events: none;'}
`

const CaretDown = styled(Icon).attrs({ name: 'caret-down', size: 10, color: 'gray' })`
    position: absolute;
    right: 7px;
    top: 10px;
`

export default React.memo(DatePicker)
