import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import StickyBox from "react-sticky-box/dist/esnext";
import { mixins, colors } from "../../vars";
import Navigation, { NAV_HEIGHT_MOBILE } from "./Navigation";
import Footer from "./Footer";
import Header from "./Header";
import { useSelector } from "react-redux";

const Layout = ({ justify = "center", children }) => {
  const scrollAreaRef = useRef();
  const [navigationOpened, setNavigationOpened] = useState(true);
  const toggleNavigation = () => setNavigationOpened((v) => !v);
  const closeNavigation = () => setNavigationOpened(true);
  const scrollToTop = () =>
    scrollAreaRef.current.scrollTo({ top: 0, left: 0, behavior: "auto" });
  const isNavigate = useSelector((state) => state.auth.isNavigate);
  useEffect(() => {
    localStorage.setItem("boolean", isNavigate);
  }, [isNavigate]);

  return (
    <>
      <Container>
        <Header />
        <Wrapper>
          {isNavigate ? (
            <Navigation
              opened={navigationOpened}
              closeNavigation={closeNavigation}
              scrollToTop={scrollToTop}
            />
          ) : null}
          <Main navigation={navigation} ref={scrollAreaRef}>
            <MainWrapper justify={justify}>{children}</MainWrapper>
          </Main>{" "}
        </Wrapper>{" "}
      </Container>
    </>
  );
};

const Container = styled.div`
  ${mixins.inset()}
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  background-color: ${colors.darkBackground};

  @media (max-width: 600px) {
    background-color: ${colors.white};
  }
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
`;

const Main = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 600px) {
    margin-bottom: ${(p) => (p.navigation ? NAV_HEIGHT_MOBILE : 0)}px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 30px;
  margin: 0 auto;
  justify-content: ${(p) => p.justify || "center"};
  align-items: flex-start;
  min-height: 100%;
  position: relative;

  @media (max-width: 600px) {
    padding: 20px;
  }

  @media (max-width: 1000px) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Content = styled.div.attrs((p) => ({
  className: `content ${p.className}`,
  children: (
    <>
      {p.children}
      {p.showFooter && <Footer />}
    </>
  ),
}))`
  flex: 1 1 auto;
  max-width: ${(p) => p.maxWidth || 620}px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;
  align-self: stretch;
  order: 2;

  @media (max-width: 1000px) {
    align-self: unset;
    margin: auto 0;
    max-width: 540px;
  }
`;

export const Sidebar = styled.div.attrs((p) => ({
  className: `sidebar ${p.mobileLast ? "is-last" : "is-first"} ${
    p.className || ""
  }`,
  as: p.nonSticky ? "div" : StickyBox,
}))`
  flex: 0 1 auto;
  max-width: 375px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;
  order: 3;

  .content + & {
    margin-left: 30px;
  }

  @media (max-width: 1000px) {
    position: static !important;
    margin: 0 auto 30px !important;
    max-width: 540px;

    &.is-first {
      order: 1;
    }

    .content + &.is-last {
      margin: 30px auto 0 !important;
    }
  }

  @media (max-width: 600px) {
    margin: 0 auto 20px !important;

    .content + &.is-last {
      margin: 20px auto 0 !important;
    }
  }
`;

Sidebar.defaultProps = {
  offsetTop: 30,
  offsetBottom: 30,
  nonSticky: false,
  mobileLast: false,
};

export { NAV_HEIGHT_MOBILE } from "./Navigation";
// export { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from './Header'

export default Layout;
