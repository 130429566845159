import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "../../../i18next/i18next";
import Flex from "./Flex";
import Button from "./Button";

const Switcher = ({ value, options = [], onChange = () => {}, ...props }) => {
  const translateOption = (text) => {
    const { t } = useTranslation();
    if (text === "Planned") {
      return t("planned");
    }
    if (text === "Actual") {
      return t("actual");
    }
  };
  return (
    <Container {...props}>
      {options.map((option) => (
        <Button
          key={option}
          color={value === option ? "gray" : "lightGray"}
          onClick={() => onChange(option)}
        >
          {translateOption(option)}
        </Button>
      ))}
    </Container>
  );
};

const Container = styled(Flex)`
  ${Button} {
    height: 30px;
    line-height: 30px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export default Switcher;
