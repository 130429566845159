import React, { Suspense } from "react";
import ReactDom from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./wealtheo/store";
import * as service from "./wealtheo/modules/auth/service";
import { authCheck } from "./wealtheo/modules/auth/store/actions";
import App from "./App";
import { Styles } from "./wealtheo/GlobalStyle/GlobalStyle";
import { BrowserRouter } from "react-router-dom";

store.dispatch(authCheck());
store.dispatch(service.getUserProfile());

const { GlobalStyle } = Styles();
const root = ReactDom.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);
