import { useEffect } from 'react'

const useClickOutside = (ref, cb, preventDefault = false, ignoreModal = false) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ignoreModal) {
                const modal = document.querySelector('.main-modal-container')
                if (modal && modal.contains(event.target)) return
            }
            if (ref?.current && !ref.current.contains(event.target)) {
                if (preventDefault) {
                    event.preventDefault()
                    event.stopPropagation()
                }
                cb()
            }
        }

        const events = preventDefault ? ['click'] : ['mousedown', 'touchstart']
        const element = preventDefault ? document.querySelector('body') : document
        events.forEach(event => element.addEventListener(event, handleClickOutside))

        return () => {
            events.forEach(event => element.removeEventListener(event, handleClickOutside))
        }
    }, [ref, cb])
}

export default useClickOutside
