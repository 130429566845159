import React, { useRef, useState, forwardRef, useEffect } from "react";
import styled from "styled-components";
import AutosizeInput from "react-input-autosize";
import { colors } from "../../vars";
import currencyMask from "../../utils/currency-mask";
import Text from "./Text";

const numberTest = /^\-?\d+\.\d*$|^\-?[\d]*$/;

const useCombinedRefs = (...refs) => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const Input = forwardRef(
  (
    {
      value,
      onChange,
      name,
      placeholder,
      prefix,
      width,
      border = false, // false, true, focus
      size = "m", // s, m, l
      fontSize,
      align = "left",
      type = "text",
      disabled = false,
      validate = () => true,
      error = null,
      className = "input",
      style,
      onFocus = () => {},
      onBlur = () => {},
      focused: focusedOutside = false,
      onFocusesSelect,
      maxLength = 11,
    },
    ref
  ) => {
    const [changed, setChanged] = useState(false);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef();
    const combinedRef = useCombinedRefs(ref, inputRef);

    const handleChange = (e) => {
      const valid = type !== "number" || numberTest.test(e.target.value);
      if (valid) setChanged(true);
      if (type === "currency")
        e.target.value = currencyMask(e.target.value).maskedValue;
      onChange(valid ? e.target.value : value);
    };

    const valid = error === null ? (changed ? validate(value) : true) : !error;

    const handleFocus = (event) => {
      onFocus();
      setFocused(true);
      if (onFocusesSelect) {
        event.currentTarget.select();
      }
    };

    const handleBlur = () => {
      onBlur();
      setFocused(false);
    };

    return (
      <>
        <Container
          disabled={disabled}
          size={size}
          width={width}
          align={align}
          fontSize={fontSize}
          valid={valid}
          onClick={() => combinedRef.current && combinedRef.current.focus()}
          style={style}
          className={className}
          border={border}
          focused={focused || focusedOutside}
        >
          {prefix && (
            <span className={`prefix ${value === "" ? "is-placeholder" : ""}`}>
              {prefix}
            </span>
          )}
          <AutosizeInput
            className={`auto-size-input ${prefix ? "" : "no-prefix"}`}
            ref={combinedRef}
            value={value}
            disabled={disabled ? "disabled" : ""}
            onChange={handleChange}
            placeholder={placeholder}
            type="text"
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={maxLength}
          />
        </Container>
      </>
    );
  }
);

const sizes = {
  padding: { s: 4, m: 6, l: 8 },
  height: { s: 25, m: 30, l: 35 },
  font: { s: 15, m: 16, l: 18 },
};

const getSize = (type, size) => sizes[type][size] || sizes[type].m;

const Container = styled.div`
  background-color: ${colors.white};
  padding: 0 ${(p) => getSize("padding", p.size)}px;
  height: ${(p) => getSize("height", p.size)}px;
  line-height: ${(p) => getSize("height", p.size)}px;
  border-radius: 4px;
  width: 100%;
  max-width: ${(p) => (p.width ? `${p.width}px` : "unset")};
  display: flex;
  flex-flow: row nowrap;
  box-shadow: ${(p) => (p.valid ? "none" : `0 0 0 3px ${colors.red}`)};
  cursor: text;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  border: ${(p) => {
    const color = p.border === true || (p.border === "focus" && p.focused);
    return `1px solid ${color ? colors.lightGray : colors.transparent}`;
  }};

  & .prefix {
    flex: 0 0 auto;
    ${(p) => p.align === "right" && "margin-left: auto;"}
    ${(p) => p.align === "center" && "margin-left: auto;"}
  }

  & .auto-size-input {
    flex: 0 1 auto;
    min-width: 0;
    ${(p) => p.align === "center" && "margin-right: auto;"}

    input {
      max-width: 100%;
      text-overflow: ellipsis;
    }

    &.no-prefix {
      ${(p) => p.align === "right" && "margin-left: auto;"}
      ${(p) => p.align === "center" && "margin-left: auto;"}
    }
  }

  & .prefix.is-placeholder {
    color: ${colors.rgba(colors.gray, 0.5)};
  }

  & input::placeholder {
    color: ${colors.rgba(colors.gray, 0.5)};
  }

  & input,
  & .prefix {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: ${(p) => p.fontSize || getSize("font", p.size)}px;
    text-align: ${(p) => p.align};
  }
`;

export default Input;
