import styled from 'styled-components'

const align = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    baseline: 'baseline',
}

const justify = {
    center: 'center',
    start: 'flex-start',
    end: 'flex-end',
    between: 'space-between',
    around: 'space-around',
    stretch: 'stretch',
    evenly: 'space-evenly',
}

const flow = {
    'row': 'row nowrap',
    'column': 'column nowrap',
    'row-wrap': 'row wrap',
    'column-wrap': 'column wrap',
}

const Flex = styled.div`
    display: flex;
    flex-flow: ${p => flow[p.flow] || flow.row};
    align-items: ${p => align[p.align] || align.start};
    justify-content: ${p => justify[p.justify] || justify.start};
`

export default Flex
