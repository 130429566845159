import styled, { css } from "styled-components";
import getResponsive from "./getResponsive";
import { colors } from "../../vars";

const font = {
  roboto: "'Roboto', sans-serif",
  raleway: "'Raleway', sans-serif",
};

const weight = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  900: 900,
  light: 300,
  normal: 400,
  medium: 500,
  "semi-bold": 600,
  bold: 700,
  black: 900,
};

const align = {
  left: "left",
  right: "right",
  center: "center",
  justify: "justify",
};

const Text = styled.p`
  font-family: ${(p) => font[p.font] || font.roboto};
  font-weight: ${(p) => weight[p.weight] || weight.normal};
  font-size: ${(p) => getResponsive(p.size, "15px")};
  color: ${(p) => (p.color ? colors[p.color] : colors.gray)};
  text-align: ${(p) => (p.align ? align[p.align] || align.left : "")};
  text-transform: ${(p) => (p.uppercase ? "uppercase" : "")};
  ${(p) => (p.lineHeight ? `line-height: ${p.lineHeight};` : "")}
  ${(p) => p.underline && "text-decoration: underline;"}
    ${(p) => p.noWrap && "white-space: nowrap;"}
    ${(p) => p.prewrap && "white-space: pre-wrap;"}
    ${(p) =>
    p.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  @media (max-width: 600px) {
    font-size: ${(p) => getResponsive(p.size, "15px", true)};
  }
`;

Text.defaultProps = {
  className: "text",
};

export default Text;
