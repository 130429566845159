/* eslint-disable no-console */
import axios from 'axios'
import store from '../store'
import { authLogout } from '../modules/auth/store/actions'

const version = 'v1'
const API_URL = process.env.REACT_APP_BASE_URL? `${process.env.REACT_APP_BASE_URL}/api/${version}/` : `/api/${version}`
axios.defaults.baseURL = API_URL
axios.defaults.headers.common.Accept = 'application/vnd.api+json'

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch(authLogout())
        }
        return Promise.reject(error)
    })

export default axios
