import React,{useEffect} from "react";
import styled from "styled-components";
import { colors, mixins } from "../../vars";
import { Icon, Text, Spacing } from "../ui";
import { NavLink } from "react-router-dom";
import moment from "moment";
 import 'moment/locale/hy-am'
// import 'moment/locale/en-ca'
// import { HEADER_HEIGHT_DESKTOP } from './Header'
import store from "../../store/index";
import * as authActions from "../../modules/auth/store/actions";
import axios from "axios";
import { LogOut } from "../../../URL/url";
import { isAuth, isNavigate } from "../../modules/auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../i18next/i18next";
import jsCookie from "js-cookie";
import Footer from "./Footer";

const MAIN_LINKS = [
  { title: "Home", icon: "menu/home", to: "/dashboard/home" },
  { title: "Accounts", icon: "menu/bank", to: "/dashboard/accounts" },
  {
    title: "Transactions",
    icon: "menu/transactions",
    to: "/dashboard/transactions",
  },
  { title: "Reports", icon: "menu/reports", to: "/dashboard/reports" },
  { title: "Settings", icon: "menu/reports", to: "/dashboard/settings" },
];

// const ACCOUNT_LINKS = [
//   // The below 2 items are temporary hidden
//   // { title: 'Settings', to: '/dashboard/settings' },
//   // { title: 'Account Info', to: '/dashbard/account-info' },
//   {
//     title: "Logout",
//     onClick: () => {
//       store.dispatch(authActions.authLogout());
//     },
//   },
// ];

const Navigation = ({
  opened = true,
  closeNavigation,
  scrollToTop = () => {},
}) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.isLanguage)
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(()=>{
    localStorage.setItem("language",JSON.stringify(language))
  },[language])
  const LogOutFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_FINTECH_URL}/${LogOut}`)
      .then((resp) => {
        console.log(resp);
        history.push("/");
        dispatch(isAuth(false));
        dispatch(isNavigate(false));
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  };
  const translateNavigation = (text) => {
    const { t } = useTranslation();
    if (text === "Home") {
      return t("home");
    }
    if (text === "Accounts") {
      return t("accounts");
    }
    if (text === "Transactions") {
      return t("transaction");
    }
    if (text === "Reports") {
      return t("reports");
    }
    if (text === "Settings") {
      return t("settings");
    }
  };

  return (
    <>
      <Desktop opened="true">
        <Spacing y="70" />
        <Text font="raleway" weight="900" size="24" uppercase>
          {moment().locale(language).format("MMMM")}{" "}
          <Text weigth="700" as="span" size="24">
            {moment().format("YYYY")}
          </Text>
        </Text>
        <Spacing y="70" />
        {MAIN_LINKS.map((link, idx) => (
          <LinkDesktop
            translateNavigation={translateNavigation}
            link={{
              ...link,
              afterClick: scrollToTop, // add close navigation?
            }}
            margin={idx < MAIN_LINKS.length - 1 ? 35 : 0}
            key={idx}
          />
        ))}
        <Spacing y="70" grow />
        <Separator />
        <Spacing y="35" />
        {/*{ACCOUNT_LINKS.map((link, idx) => (*/}
        {/*  <LinkDesktop*/}
        {/*    link={link}*/}
        {/*    margin={idx < ACCOUNT_LINKS.length - 1 ? 15 : 0}*/}
        {/*    key={idx}*/}
        {/*  />*/}
        {/*))}*/}
        <button onClick={LogOutFunc}>{t("logOut")}</button>
        <Spacing y="35" />
      </Desktop>
      <Mobile>
        {MAIN_LINKS.map((link, idx) => (
          <LinkMobile link={{ ...link, afterClick: scrollToTop }} key={idx} />
        ))}
      </Mobile>
      {opened && <CloseOutside onClick={closeNavigation} />}
    </>
  );
};

export const NAV_HEIGHT_MOBILE = 65;

const Desktop = styled.div`
  width: 300px;
  margin: 20px;
  background-color: ${colors.white};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 30px;
  position: relative;

  @media (max-width: 1300px) {
    ${mixins.transition("transform")}
    transform: ${(p) => (p.opened ? "none" : "translateX(-100%)")};
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 0 10px ${colors.rgba(colors.black, 0.1)};
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const CloseOutside = styled.div`
  position: fixed;

  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: none;

  @media (min-width: 601px) and (max-width: 1385px) {
    display: block;
  }
`;

const LinkDesktop = styled.div.attrs((p) => ({
  children: (
    <Text
      as={p.link.onClick ? "button" : NavLink}
      onClick={p.link.onClick || p.link.afterClick || null}
      to={p.link.to}
      font="raleway"
      weight="500"
      size="15"
      color="inherit"
    >
      {p.translateNavigation(p.link.title)}
    </Text>
  ),
}))`
  display: block;
  color: ${colors.darkGray};
  text-transform: uppercase;

  .text.active {
    color: ${colors.gold};
  }

  &:not(:last-child) {
    margin-bottom: ${(p) => p.margin || 0}px;
  }
`;

const Separator = styled.div`
  margin: 0 - 30px;
  height: 1px;
  width: 100%;
  background-color: ${colors.gray};
`;

const Mobile = styled.div`
  flex-flow: row nowrap;
  justify-content: space-evenly;
  height: ${NAV_HEIGHT_MOBILE}px;
  align-items: stretch;
  background-color: ${colors.darkGray};
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const LinkMobile = styled(NavLink).attrs((p) => ({
  to: p.link.to,
  children: (
    <span onClick={p.link.afterClick || null}>
      <Icon name={p.link.icon} size="22" />
      <Text font="raleway" weight="500" size="8" color="inherit" uppercase>
        {p.link.title}
      </Text>
    </span>
  ),
}))`
  color: ${colors.white};
  width: 25%;

  span {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &.active {
    color: ${colors.gold};
  }

  ${Text} {
    margin-top: 7px;
    white-space: nowrap;
  }
`;

export default Navigation;
