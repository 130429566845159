import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.css";

export const Ftr = false

const Footer = () => {
    return (
        <footer>
            <div className="footerwrapp">
                <div className="footerlogobox">
                    <h2>FINTECH</h2>
                    <p>
                        See all your money accounts in one place, in real-time. Fintech
                        automates your budgeting process and helps you find ways to save and
                        set aside money toward future goals.
                    </p>
                </div>
                <ul className="footerlinks">
                    <li className="coltitle">Company</li>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contacts</Link>
                    </li>
                </ul>
                <ul>
                    <li className="coltitle">Contacts</li>
                    <li>Address - Baghramyan 18</li>
                    <li>Phone - 041277439</li>
                    <li>Email - arpifytech@gmail.com</li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
