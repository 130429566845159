import React from "react";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "./Heder.css";
import ButtonLink from "./ButtonLink";
import { useSelector } from "react-redux";

const Hamburger = ({ state, setState }) => {
  const selector = useSelector((state) => state.auth.isAuth);
  return (
    <>
      <div className={`${!state ? "respMenu2" : "respMenu"}`}>
        <p
          className="close"
          onClick={() => {
            setState(false);
          }}
        >
          {" "}
          <CloseIcon />
        </p>
        <ul>
          <li
            onClick={() => {
              setState(false);
            }}
          >
            <NavLink to="/">
              <b> Home</b>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setState(false);
            }}
          >
            <NavLink to="/about">
              <b>About</b>
            </NavLink>
          </li>
          <li
            onClick={() => {
              setState(false);
            }}
          >
            <NavLink to="/contact">
              <b>Contact</b>
            </NavLink>
          </li>
          {selector ? (
            <li>
              <NavLink to="/account">Account</NavLink>
            </li>
          ) : (
            <>
              <li
                onClick={() => {
                  setState(false);
                }}
              >
                <ButtonLink to="/auth/login">
                  <b>Sing in</b>
                </ButtonLink>
              </li>
              <li
                onClick={() => {
                  setState(false);
                }}
              >
                <ButtonLink to="/auth/registration">
                  <b>Sing Up</b>
                </ButtonLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Hamburger;
