import {lazy} from "react";

export default [
    {
        path: '/auth/login',
        auth: false,
        exact: false,
        component: lazy(() => import('./pages/LogIn/index')),
    },
    {
        path: '/auth/registration',
        auth: false,
        exact: false,
        component: lazy(() => import('./pages/Registration/index')),
    },
    {
        path: '/auth/forgotPassword',
        auth: false,
        exact: false,
        component: lazy(() => import('./pages/ForgotPassword/index')),
    },
    {
        path: '/auth/resetPassword',
        auth: false,
        exact: false,
        component: lazy(() => import('./pages/ResetPassword/index')),
    },
]
