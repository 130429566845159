import {
    OPEN,
    CLOSE,
} from './action-types'

const initialState = {
    component: null,
    props: null,
    options: null,
    opened: false,
}

const modal = (state = initialState, { type, payload = null }) => {
    switch (type) {
    case OPEN:
        return { ...state, opened: true, ...payload }
    case CLOSE:
        return { ...state, opened: false }
    default:
        return state
    }
}

export default modal
