import { combineReducers } from 'redux'

import {
    ADD_TRANSACTION,
    GET_TRANSACTION_CATEGORIES,
    GET_TRANSACTIONS_API,
    PATCH_TRANSACTION_API,
    GET_BANK_ACCOUNTS_API,
    SET_NEW_BANK_ACC_LINK_API,
    BANK_ACC_LINK_USED,
    DELETE_BANK_ACC,
    ACCOUNT_NEED_RESYNC,
    GET_SNOWBALL,
    PATCH_SNOWBALL,
    CREATE_SNOWBALL,
    DELETE_TRANSACTION,
} from './action-types'

const initialState = {
    transactions: {
        categorized: [],
        unCategorized: [],
    },
    transactionCategories: [],
    bankAccounts: [],
    bankAccountLink: { linkToken: null },
    snowball: { attributes: { amount: '0.00' } },
}

const transactions = (state = initialState.transactions, { type, payload = null }) => {
    switch (type) {
    case ADD_TRANSACTION:
        return addTransaction(state, payload)
    case GET_TRANSACTIONS_API:
        return getTransactionsApi(state, payload)
    case PATCH_TRANSACTION_API:
        return patchTransactionApi(state, payload)
    case DELETE_TRANSACTION:
        return deleteTransaction(state, payload)    
    default:
        return state
    }
}

const transactionCategories = (state = initialState.transactionCategories, { type, payload = null }) => {
    switch (type) {
    case GET_TRANSACTION_CATEGORIES:
        return collectTransactionCategories(state, payload)
    default:
        return state
    }
}

const bankAccounts = (state = initialState.bankAccounts, { type, payload = null }) => {
    switch (type) {
    case GET_BANK_ACCOUNTS_API:
        return payload
    case DELETE_BANK_ACC:
        return deleteBankAccount(state, payload)
    case ACCOUNT_NEED_RESYNC:
        return setNeedResyncToAccount(state, payload)    
    default:
        return state
    }
}

const bankAccountLink = (state = initialState.bankAccountLink, { type, payload = null }) => {
    switch (type) {
    case SET_NEW_BANK_ACC_LINK_API:
        return { ...state, ...payload }
    case BANK_ACC_LINK_USED:
        return null
    default:
        return state
    }
}

const snowball = (state = initialState.snowball, { type, payload = null }) => {
    switch (type) {
    case CREATE_SNOWBALL:
        return createSnowball(state, payload)
    case PATCH_SNOWBALL:
        return patchSnowball(state, payload)
    case GET_SNOWBALL:
        return payload
    default:
        return state
    }
}

function createSnowball(state, payload) {
    return payload
}

function patchSnowball(state, payload) {
    return payload
}

function deleteBankAccount(state, payload) {
    const newState = state.filter(item => item.id !== payload)
    return newState
}

function setNeedResyncToAccount(state, payload) {
    const failedAccountIdx = state.findIndex(account => account.id === payload)
    const failedAccount = state[failedAccountIdx]
    failedAccount.needResync = true
    state.splice(failedAccountIdx, 1)
    return [...state, failedAccount]
}

function collectTransactionCategories(state, payload){
    return payload
}

function addTransaction(state, payload) {
    state = { ...state, categorized: [payload, ...state.categorized]}
    return state
}

function getTransactionsApi(state, payload) {
    return payload
}

function patchTransactionApi(state, payload) {
    const categorizedIdx = state.categorized.findIndex(trn => trn.id === payload.id)
    const unCategorizedIdx = state.unCategorized.findIndex(trn => trn.id === payload.id)
    if (categorizedIdx !== -1) {
        state.categorized.splice(categorizedIdx, 1)
    }
    if (unCategorizedIdx !== -1) {
        state.unCategorized.splice(unCategorizedIdx, 1)
    }
    if (payload.attributes.category) {
        state.categorized.splice(categorizedIdx, 0, payload)
    } else {
        state.unCategorized.splice(unCategorizedIdx, 0, payload)
    }
    return Object.assign({}, state)
}

function deleteTransaction(state, payload) {
    const categorized = state.categorized.filter(transaction => transaction.id !== payload.id)
    return { ...state, categorized }
}

const dashboard = combineReducers({
    transactions,
    transactionCategories,
    bankAccounts,
    bankAccountLink,
    snowball,
})
export default dashboard
