// auth action types
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const CHECK_COMPLETED_WIZARD = "CHECK_COMPLETED_WIZARD";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SEND_EMAIL = "SEND_EMAIL";
export const IS_AUTH = "IS_AUTH";
export const IS_NAVIGATE = "IS_NAVIGATE";
export const IS_AVATAR = "IS_AVATAR";
export const IS_CURRENCY = "IS_CURRENCY"
export const IS_LANGUAGE = "IS_LANGUAGE"
export const IS_LIGHT_DARK = "IS_LIGHT_DARK";
export const IS_PRODUCTS ="IS_PRODUCTS"

export default {
  AUTH_CHECK,
  AUTH_LOGOUT,
  CHECK_COMPLETED_WIZARD,
  GET_USER_PROFILE,
  SEND_EMAIL,
  IS_AUTH,
  IS_NAVIGATE,
  IS_AVATAR,
  IS_CURRENCY,
  IS_LANGUAGE,
  IS_LIGHT_DARK,
  IS_PRODUCTS
};
