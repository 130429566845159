// auth action types
export const INCOME_LIST = 'INCOME_LIST'
export const ADD_INCOME = 'ADD_INCOME'
export const SET_GROUP_DATA = 'SET_GROUP_DATA'
export const REMOVE_INCOME = 'REMOVE_INCOME'
export const UPDATE_INCOME_LIST = 'UPDATE_INCOME_LIST'

// DEBTS CONSTANT
export const ADD_DEBT = 'ADD_DEBT'
export const UPDATE_DEBTS_LIST = 'UPDATE_DEBTS_LIST'
export const UPDATE_SINGLE_DEBTS = 'UPDATE_SINGLE_DEBTS'

export const DEBT_LIST = 'DEBT_LIST'
export const REMOVE_DEBT = 'REMOVE_DEBT'

export const GET_EMERGENCY_FUNDS = 'GET_EMERGENCY_FUNDS'
export const EMERGENCY_FUNDS_UPDATED = 'EMERGENCY_FUNDS_UPDATED'

export const GET_FINAL_REVIEW = 'GET_FINAL_REVIEW'
export const CALCULATE_TOTAL = 'CALCULATE_TOTAL'
export const CALCULATE_TOTAL_ADD_DEBT = 'CALCULATE_TOTAL_ADD_DEBT'
export const CALCULATE_TOTAL_REMOVE_ITEM = 'CALCULATE_TOTAL_REMOVE_ITEM'
export const SET_ACTUAL_BUDGET_MONTH = 'SET_ACTUAL_BUDGET_MONTH'
export const SET_ACTUAL_DEBT_MONTH = 'SET_ACTUAL_DEBT_MONTH'

export default {
    INCOME_LIST,
    ADD_INCOME,
    SET_GROUP_DATA,
    REMOVE_INCOME,
    UPDATE_INCOME_LIST,
    ADD_DEBT,
    UPDATE_DEBTS_LIST,
    DEBT_LIST,
    REMOVE_DEBT,
    UPDATE_SINGLE_DEBTS,
    GET_EMERGENCY_FUNDS,
    EMERGENCY_FUNDS_UPDATED,
    GET_FINAL_REVIEW,
    CALCULATE_TOTAL,
    CALCULATE_TOTAL_ADD_DEBT,
    CALCULATE_TOTAL_REMOVE_ITEM,
    SET_ACTUAL_BUDGET_MONTH,
    SET_ACTUAL_DEBT_MONTH,
}
