/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import {
  IS_CURRENCY,
  IS_LANGUAGE,
  AUTH_CHECK,
  AUTH_LOGOUT,
  CHECK_COMPLETED_WIZARD,
  GET_USER_PROFILE,
  IS_AUTH,
  SEND_EMAIL,
  IS_NAVIGATE,
  IS_AVATAR,
  IS_LIGHT_DARK,
  IS_PRODUCTS
} from "./action-types";

export function authCheck() {
  return {
    type: AUTH_CHECK
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT
  };
}

export function userCompletedWizard(payload) {
  return {
    type: CHECK_COMPLETED_WIZARD,
    payload
  };
}

export function getUserProfile(payload) {
  return {
    type: GET_USER_PROFILE,
    payload
  };
}

export function SendEmail(payload) {
  return {
    type: SEND_EMAIL,
    payload
  };
}

export function isAuth(payload) {
  return {
    type: IS_AUTH,
    payload
  };
}

export function setDarkLight(payload) {
  return {
    type: IS_LIGHT_DARK,
    payload
  };
}

export function isNavigate(payload) {
  return {
    type: IS_NAVIGATE,
    payload
  };
}

export function isAvatar(payload) {
  return {
    type: IS_AVATAR,
    payload
  };
}

export function isCurrency(payload) {
  return {
    type: IS_CURRENCY,
    payload
  };
}

export function isLanguage(payload) {
  return {
    type: IS_LANGUAGE,
    payload
  };
}
export function isProducts(payload) {
  return {
    type: IS_PRODUCTS,
    payload
  };
}
