// import modular routes
import webRoutes from '../modules/web/routes'
import authRoutes from '../modules/auth/routes'
import setupRouts from '../modules/setup/routes'
import dashboardRoutes from '../modules/dashboard/routes'

export default [
    ...webRoutes,
    ...authRoutes,
    ...setupRouts,
    ...dashboardRoutes,
]
