export const BudgetItemType = {
    Income: 0,
    Expense: 1,
    ExpensesNecessary: 1,
    ExpensesDiscretionary: 1,
    Debt: 2,
}

export const BudgetTypeTotals = {
    Income: 'income',
    Expense: 'expenses',
    ExpensesNecessary: 'expenses',
    ExpensesDiscretionary: 'expenses',
    Debt: 'debts',
}

export const ExpenseType = {
    ExpensesNecessary: 0,
    ExpensesDiscretionary: 1,
}

// NOTE: DON'T change ordering
export const DEBT_TYPES_NAMES = ['Credit Card', 'Personal Loan', 'Student Loan', 'Auto Loan', '', 'Other']

export const DebtTypes = {
    DEBT_TYPE_CREDIT_CARD: 0,
    DEBT_TYPE_PERSONAL_LOAD: 1,
    DEBT_TYPE_STUDENT_LOAN: 2,
    DEBT_TYPE_AUTO_LOAD: 3,
    DEBT_TYPE_OTHER: 5,
}
