import React from "react";
import Modal from "./wealtheo/common/Modal";
import Footer from "./wealtheo/common/Layout/Footer";
import Routes from "./wealtheo/routes";
import { useSelector } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { colors } from "./wealtheo/vars";

const App = () => {
  let LightDark = useSelector((state) => state.auth.DarkLight);

  const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      font: inherit;
      line-height: inherit;
      color: inherit;
      cursor: inherit;
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    *:focus {
      outline: none;
    }

    html {
      overflow: hidden;
      background: ${colors.background};
      height: 100%;
    }

    body {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      overflow: hidden;
      min-width: 375px;
      width: 100%;
      font-size: 15px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.25;
      background: ${colors.background};
      color: ${colors.gray};
      min-height: 100%;
      cursor: default;
    }

    body a {
      color: inherit;
    }

    body button {
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: transparent;
      border-radius: 0;
      font: inherit;
    }

    body input,
    body textarea {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -moz-appearance: none;
      -webkit-appearance: none;
      display: block;
      width: 100%;
      background: transparent;
    }

    body input::placeholder,
    body textarea::placeholder {
      color: inherit;
      opacity: 1;
    }

    ul,
    ol {
      list-style: none;
    }

    a,
    button {
      cursor: pointer;
    }

    p {
      font-family: 'Roboto', sans-serif;
    }

    input,
    textarea {
      cursor: text;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    img,
    svg {
      display: block;
      overflow: visible;
    }

    b,
    strong {
      font-weight: 700;
    }

    q:before,
    q:after {
      content: unset
    }

    ::selection {
      background-color: ${colors.lightGray};
      color: ${colors.white};
    }

    .juIjBm, .question input, .question textarea, .help {
      background-color: ${!LightDark ? "#efeff1" : "rgb(53 54 58) !important"};
      color: ${!LightDark ? "" : "white !important"};
    }

    .eTGFTv .text.active {
      color: #AD9254 !important;
      border: none;
    }

    .iTcBkN .text.active {
      color: #AD9254 !important;
      border: none;
    }

    .active {
      color: #AD9254 !important;
      border: none;
    }
    .ReactFlagsSelect-module_selectOption__3pcgW:hover{
      background-color: ${!LightDark ? "" : "#eaeaea70 !important"};
    }
    .lEowq, .eIgTMW, .bCdAgA, .brbjBX, .lmKDSz, .ReactFlagsSelect-module_selectBtn__19wW7, .ReactFlagsSelect-module_fullWidthOptions__1XeR6, .question {
      background-color: ${!LightDark ? "" : "rgb(41,43,45) !important"};
      color: ${!LightDark ? "" : "white !important"};
    }


    .iomjte input, .footerlogobox p, .googleLogin, .googleLogin, .form, .form .input, .createAccount, .showIconLogin, .registration_account, .welcome-to-fintech, .already, .welBackFin, .login_word, .formEmail label, .formLogin input, .formPassword label {
      color: ${!LightDark ? "" : "white !important"};
    }

    .gSsdDV:after {
      background-color: ${!LightDark ? "" : "#595348 !important"};
    }

    .ljRqRk,.hYZeVx{
      color: ${!LightDark ? "" : "black !important"};
    }

    .text,.kQYuiD,.gRZWot,.kmPowd,.bILAWT,.cuukwn,.gqiJzV,.jsFYXH,.fqNEcB,.hVpKQN,.jvWCpT,.eZBcXq,.kbodhw {
      color: ${!LightDark ? "" : "white !important"};
    }
    
    
    .hnbbKA{
      background-color: ${!LightDark ? "" : "#595348 !important"};
    }

    .jykOtW:after{
      background-color: ${!LightDark ? "" : "#595348 !important"};
    }
    
    .fECRzL > div,.dPMaod > div{
      background-color: ${!LightDark ? "" : "#595348 !important"};
    }
    .ksajxB {
      background-color: ${
        !LightDark ? "#efeff1" : "rgba(88,91,106,0.75) !important"
      };
    }
    .kllLmr, .hhKrCv{
      background-color: ${!LightDark ? "" : "rgb(88 91 106) !important"};
    }
  `;

  return (
    <>
      <GlobalStyle />
      <Routes />
      <Modal />
      <Footer />
    </>
  );
};
export default App;
