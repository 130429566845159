// import lib
import { lazy } from "react";

const routes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("./pages/home/index")),
  },
  {
    path: "/about",
    exact: true,
    component: lazy(() => import("./pages/about/index")),
  },
  {
    path: "/contact",
    exact: true,
    component: lazy(() => import("./pages/contacts")),
  },
  {
    path: "/account",
    exact: true,
    component: lazy(() => import("./pages/account")),
  },
];

export default routes;
