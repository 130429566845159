export const LoginUser = "loginUser";
export const Register = "register";
export const Google_Login = "googleLogin";
export const Forgot_Password = "forgot_password";
export const Reset_Password = "reset_password";
export const VerifyCode = "verify_email";
export const ContactMessage = "send_message";
export const LogOut = "logout";
export const Profile = "profile";
export const GetProducts = "get_products"
