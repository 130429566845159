import styled from 'styled-components'
import getResponsive from './getResponsive'

const Spacing = styled.div`
    width: ${p => getResponsive(p.x, 'auto')};
    height: ${p => getResponsive(p.y, 'auto')};
    flex-shrink: 0;
    ${p => p.grow && 'flex-grow: 1;'};

    @media (max-width: 600px) {
        width: ${p => getResponsive(p.x, 'auto', true)};
        height: ${p => getResponsive(p.y, 'auto', true)};
    }
`

export default Spacing
