import * as actions from './store/actions'
import Http from '../../utils/Http'
import Transformer from '../../utils/Transformer'

function transformRequest(parms) {
    return Transformer.send(parms)
}
  
function transformResponse(params) {
    return Transformer.fetch(params)
}

export function getUserProfile() {
    return dispatch =>  {
        Http.get('/profile').then(res => {
            const { data: { data: { attributes } } } = res
            dispatch(actions.userCompletedWizard(attributes.setup_completed_at))
            dispatch(actions.getUserProfile(transformResponse(res.data.data)))
        }).catch(() => {})
    }
}