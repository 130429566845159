// auth action types
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const GET_TRANSACTION_CATEGORIES = 'GET_TRANSACTION_CATEGORIES'
export const GET_TRANSACTIONS_API = 'GET_TRANSACTIONS_API'
export const PATCH_TRANSACTION_API = 'PATCH_TRANSACTION_API'
export const GET_BANK_ACCOUNTS_API = 'GET_BANK_ACCOUNTS_API'
export const SET_NEW_BANK_ACC_LINK_API = 'SET_NEW_BANK_ACC_LINK_API'
export const BANK_ACC_LINK_USED = 'BANK_ACC_LINK_USED'
export const DELETE_BANK_ACC = 'DELETE_BANK_ACC'
export const ACCOUNT_NEED_RESYNC = 'ACCOUNT_NEED_RESYNC'
export const GET_SNOWBALL = 'GET_SNOWBALL'
export const PATCH_SNOWBALL = 'PATCH_SNOWBALL'
export const CREATE_SNOWBALL = 'CREATE_SNOWBALL'
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'

export default {
    ADD_TRANSACTION,
    GET_TRANSACTION_CATEGORIES,
    GET_TRANSACTIONS_API,
    PATCH_TRANSACTION_API,
    GET_BANK_ACCOUNTS_API,
    SET_NEW_BANK_ACC_LINK_API,
    BANK_ACC_LINK_USED,
    DELETE_BANK_ACC,
    ACCOUNT_NEED_RESYNC,
    GET_SNOWBALL,
    PATCH_SNOWBALL,
    CREATE_SNOWBALL,
    DELETE_TRANSACTION,
}
