import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { isNavigate, isCurrency, isLanguage} from "../../modules/auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import Hamburger from "./Hamburger";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import "../../../i18next/i18next";
import i18next from "i18next";
import "./Heder.css";

const Header = () => {
  let [state, setState] = useState(false);
  const isAuthSe = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    localStorage.setItem("item", JSON.stringify(isAuthSe));
  }, [isAuthSe]);
  const changesLanguage =(event) =>{
    if(event === "US"){
      i18next.changeLanguage(event)
      dispatch(isLanguage("en"))
      dispatch(isCurrency("$"))

    }
    if(event === "AM"){
    i18next.changeLanguage(event)
      dispatch(isLanguage("am"))
      dispatch(isCurrency("֏"))

    }
    window.location.reload()
    // ֏
  }
  return (
    <header
      className="header"
      onClick={() => {
        // dispatch(isAuth(true));
        // console.log('ss', selector)
      }}
    >
      <div className="logo">
        <Link to="/" onClick={() => dispatch(isNavigate(false))} >FINTECH</Link>
      </div>
      <nav>
        <ul className="menu">
          <li>
            <ReactFlagsSelect
                countries={["US","AM"]}
                customLabels={{ US: "USA", AM:"AM" }}
                placeholder={t("SelectLanguage")}
                onSelect={changesLanguage}
                selectedSize={14}
                optionsSize={14}
            />
          </li>
          <li>
            <NavLink to="/" onClick={() => dispatch(isNavigate(false))} className="headerLink">
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={() => dispatch(isNavigate(false))} className="headerLink">
                {t("about")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={() => dispatch(isNavigate(false))} className="headerLink">
                {t("contact")}
            </NavLink>
          </li>
          {isAuthSe ? (
            <li>
              <NavLink to="/account" onClick={() => dispatch(isNavigate(true))} className="headerLink">
                  {t("account")}
              </NavLink>
            </li>
          ) : (
            <>
              <li>
                <NavLink to="/auth/login" className="headerLink">{t("singin")}</NavLink>
              </li>
              <li>
                <NavLink to="/auth/registration" className="headerLink">{t("singup")}</NavLink>
              </li>
            </>
          )}
        </ul>
        <p
          className="respHamburger"
          onClick={() => {
            setState(true);
          }}
        >
          <b>
            <MenuIcon />
          </b>
        </p>
        <Hamburger state={state} setState={setState} />
      </nav>
      <div className={`${!state ? "fon2" : "fon"}`}></div>
    </header>
  );
};

export default Header;
