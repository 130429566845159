import React, { useRef, useState, useCallback, useEffect, useLayoutEffect, useImperativeHandle } from 'react'
import styled from 'styled-components'
import useClickOutside from '../../hooks/useClickOutside'

const EVENTS = ['scroll', 'resize']

const Dropdown = React.forwardRef(({
    manual = false,
    stopPropagation = true,
    alignDropdownRight = false,
    stretched = false,
    onFocus = () => {},
    onBlur = () => {},
    trigger = null,
    width,
    maxDropdownHeight = 200,
    children,
    className,
    style,
}, ref) => {
    const [opened, setOpened] = useState(false)
    const [visible, setVisible] = useState(false)
    const containerRef = useRef()
    const contentRef = useRef()
    const triggerRef = useRef()
    const timeoutRef = useRef({ toggle: null, observe: null })
    const rafRef = useRef()

    useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        close: () => toggle(false),
        open: () => toggle(true),
    }))

    const setPosition = useCallback(() => {
        const trigger = triggerRef.current
        const content = contentRef.current
        if (!content || !trigger) return

        const { innerWidth, innerHeight } = window
        const t = trigger.getBoundingClientRect()
        const c = content.getBoundingClientRect()

        const bottomAvailable = t.top + t.height + 5 + c.height > innerHeight - 10
        const topAvailable = t.top - 5 - c.height < 10
        const rightAvailable = t.left + c.width > innerWidth - 10
        const leftAvailable = t.left + t.width - c.width < 10

        const fromBottom = bottomAvailable && !topAvailable
        const fromRight = alignDropdownRight ? !leftAvailable : rightAvailable && !leftAvailable

        const top = fromBottom ? t.top - 5 - c.height : t.top + t.height + 5
        const left = fromRight ? t.left + t.width - c.width : t.left
        const minWidth = stretched ? t.width : 0

        content.style.position = 'fixed'
        content.style.top = `${top}px`
        content.style.left = `${left}px`
        content.style.minWidth = `${minWidth}px`

        setVisible(true)
    }, [alignDropdownRight, stretched])

    const observeResize = useCallback(() => {
        const trigger = triggerRef.current
        const content = contentRef.current

        const getValues = () => {
            const { width, height, top, left } = trigger.getBoundingClientRect()

            return {
                triggerWidth: width,
                triggerHeight: height,
                triggerTop: top,
                triggerLeft: left,
                contentWidth: content.offsetWidth,
                contentHeight: content.offsetHeight,
            }
        }

        const startValues = getValues()

        const frame = () => {
            rafRef.current = requestAnimationFrame(frame)
            const newValues = getValues()
            if (JSON.stringify(startValues) !== JSON.stringify(newValues)) {
                setPosition()
                Object.assign(startValues, newValues)
            }
        }

        cancelAnimationFrame(rafRef.current)
        rafRef.current = requestAnimationFrame(frame)
    }, [setPosition])

    const toggle = useCallback((next = null) => {
        setOpened(current => {
            const opened = next === null ? !current : next
            if (opened) {
                onFocus()
            } else {
                setTimeout(onBlur)
                setVisible(false)
            }
            return opened
        })
    }, [onBlur, onFocus])

    const onTriggerClick = useCallback(event => {
        if (!manual) toggle()
        if (stopPropagation) {
            event.preventDefault()
            event.stopPropagation()
        }
    }, [manual, stopPropagation, toggle])

    useLayoutEffect(() => {
        const raf = rafRef.current

        if (opened) {
            EVENTS.forEach(event => window.addEventListener(event, setPosition))
            timeoutRef.current.observe = setTimeout(() => {
                observeResize()
                setPosition()
            })
        } else {
            clearTimeout(timeoutRef.current.observe)
            EVENTS.forEach(event => window.removeEventListener(event, setPosition))
            cancelAnimationFrame(raf)
        }
    }, [opened, setPosition, observeResize])

    useEffect(() => {
        const raf = rafRef.current

        return () => {
            EVENTS.forEach(event => window.removeEventListener(event, setPosition))
            cancelAnimationFrame(raf)
        }
    }, [setPosition])

    useClickOutside(containerRef, () => {
        toggle(false)
    })

    return (
        <Container
            ref={containerRef}
            width={width}
            style={style}
            className={`${className} ${opened ? 'opened' : 'closed'}`}
        >
            <Trigger ref={triggerRef} onClick={onTriggerClick}>
                {trigger}
            </Trigger>
            {opened && (
                <Content visible={visible} ref={contentRef} height={maxDropdownHeight}>
                    {children}
                </Content>
            )}
        </Container>
    )
})

const Container = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
  max-width: ${p => p.width ? `${p.width}px` : 'unset'};
`

const Trigger = styled.div`
  position: relative;
  cursor: pointer;
`

const Content = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${p => p.height || '200'}px;
  box-shadow: 0px 4px 16px #0002;
  z-index: 1000;
  opacity: ${p => p.visible ? 1 : 0};
`

export default React.memo(Dropdown)
