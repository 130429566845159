import styled, { keyframes } from 'styled-components'
import Icon from './Icon'

const spinAnimation = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`

const Spinner = styled(Icon).attrs(p => ({
    size: p.size,
    name: p.icon,
    color: p.color,
    inline: true,
}))`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    animation-name: ${spinAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`

Spinner.defaultProps = {
    size: 20,
    color: 'white',
    icon: 'spinner',
}

export default Spinner
