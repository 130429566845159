import { combineReducers } from 'redux'
import { BudgetItemType, BudgetTypeTotals } from '../../../utils/enums'
import currencyMask from '../../../utils/currency-mask'
import moment from 'moment'

import {
    INCOME_LIST,
    ADD_INCOME,
    SET_GROUP_DATA,
    REMOVE_INCOME,
    UPDATE_INCOME_LIST,
    UPDATE_DEBTS_LIST,
    DEBT_LIST,
    REMOVE_DEBT,
    UPDATE_SINGLE_DEBTS,
    GET_EMERGENCY_FUNDS,
    EMERGENCY_FUNDS_UPDATED,
    GET_FINAL_REVIEW,
    CALCULATE_TOTAL,
    CALCULATE_TOTAL_ADD_DEBT,
    CALCULATE_TOTAL_REMOVE_ITEM,
    SET_ACTUAL_BUDGET_MONTH,
    SET_ACTUAL_DEBT_MONTH,
} from './action-types'

const initialState = {
    incomeBudgetItem: {
        Income: [],
        ExpensesNecessary: [],
        ExpensesDiscretionary: [],
        Debt: [],
    },
    groupData: {
        Income: null,
        ExpensesNecessary: null,
        ExpensesDiscretionary: null,
        Debt: null,
    },
    debts: [],
    fund: { planned: '0.00', updated: false },
    finalReview: {
        income: 0,
        expenses: 0,
        debts: 0,
        emergencyFund: 0,
    },
}

const debts = (state = initialState.debts, { type, payload = null }) => {
    switch (type) {
    case UPDATE_DEBTS_LIST:
        return updateDebtList(state, payload)
    case UPDATE_SINGLE_DEBTS:
        return updateSingleDebtList(state, payload)
    case DEBT_LIST:
        return debtlist(state, payload)
    case REMOVE_DEBT:
        return removeDebtItemFromList(state, payload)
    case SET_ACTUAL_DEBT_MONTH:
        return setActualDebtByMonth(state, payload)
    default:
        return state
    }
}

const budget = (state = initialState.incomeBudgetItem, { type, payload = null }) => {
    switch (type) {
    case INCOME_LIST:
        return list(state, payload)
    case ADD_INCOME:
        return addBudgetItem(state, payload)
    case REMOVE_INCOME:
        return removeItemFromList(state, payload)
    case UPDATE_INCOME_LIST:
        return updateList(state, payload)
    case SET_ACTUAL_BUDGET_MONTH:
        return setActualBudgetByMonth(state, payload)
    default:
        return state
    }
}

const groupData = (state = initialState.groupData, { type, payload = null }) => {
    switch (type) {
    case SET_GROUP_DATA:
        return setGroupData(state, payload)
    default:
        return state
    }
}

const emergencyFund = (state = initialState.fund, { type, payload = null }) => {
    switch (type) {
    case GET_EMERGENCY_FUNDS:
        return getEmergencyFunds(state, payload)
    case EMERGENCY_FUNDS_UPDATED:
        return Object.assign({}, { ...state, updated: payload })
    default:
        return state
    }
}

const finalReview = (state = initialState.finalReview, { type, payload = null }) => {
    switch (type) {
    case GET_FINAL_REVIEW:
        return getFinalReview(state, payload)
    case CALCULATE_TOTAL:
        return calculateTotal(state, payload)
    case CALCULATE_TOTAL_ADD_DEBT:
        return calculateTotalAddDebt(state, payload)
    case CALCULATE_TOTAL_REMOVE_ITEM:
        return calculateTotalRemoveItem(state, payload)
    default:
        return state
    }
}

function updateList(state, payload) {
    const { type, item } = payload
    if (!type) {
        return state
    }
    const updated = state[type]
        .map(
            budgetItem => {
                if (budgetItem.id === item.id) {
                    return item
                }
                return budgetItem
            },
        )
    const isNew = updated.find(budgetItem => budgetItem.id === item.id)
    if (!isNew) {
        updated.push({ ...item, isNew: true })
    }
    return Object.assign({}, { ...state, [type]: updated })
}

function updateSingleDebtList(state, payload) {
    const items = state
    const foundIndex = items.findIndex(x => x.id == payload.id)
    items[foundIndex] = payload
    state = Object.assign([], items)
    return state
}

function list(state, payload) {
    const { budgetType } = payload
    state = Object.assign({}, { ...state, [budgetType]: payload.items })
    return state
}

function addBudgetItem(state, payload) {
    const { item, type } = payload
    state = Object.assign({}, { ...state, [type]: [...state[type], item]})
    return state
}

function removeDebtItemFromList(state, payload) {
    const newState = state.filter(budgetItem => {
        return budgetItem.id !== payload.id
    })
    return newState
}

function removeItemFromList(state, payload) {
    const { item, type } = payload
    const newState = state[type].filter(budgetItem => {
        return budgetItem.id !== item.id
    })
    return Object.assign({}, { ...state, [type]: newState })
}

// DEBTS
function updateDebtList(state, payload) {
    state = Object.assign([...state, payload])
    return state
}

function debtlist(state, payload) {
    state = Object.assign([], payload)
    return state
}

function getEmergencyFunds(state, payload) {
    state = Object.assign(
        {},
        {
            ...state,
            planned: payload.attributes.planned,
            id: payload.id,
        },
    )
    return state
}

function getFinalReview(state, payload) {
    state = Object.assign({}, payload)
    return state
}

function calculateTotalAddDebt(state, payload) {
    const addedAmount = currencyMask(payload).value
    return { ...state, debts: state.debts + addedAmount }
}

function calculateTotalRemoveItem(state, payload) {
    const { budgetType, amount } = payload
    const key = BudgetTypeTotals[budgetType]
    return {
        ...state,
        [key]: state[key] - currencyMask(amount).value,
    }
}

function calculateTotal(state, payload) {
    const sumItems = (items, changedItem) => {
        return items.reduce(
            (sum, item) =>  {
                if (item.id === changedItem.data.id) {
                    return sum + parseFloat(changedItem.data.attributes.planned)
                }
                return sum + parseFloat(item.attributes.planned)
            },
            0,
        )
    }

    const sumDebts = (debts, changedItem) => {
        return debts.reduce(
            (sum, item) =>  {
                if (item.id === changedItem.item.id) {
                    return sum + currencyMask(changedItem.startingBalance).value
                }
                return sum + currencyMask(item.attributes.startingBalance).value
            },
            0,
        )
    }

    const { budgetType, budget, item } = payload
    const key = BudgetTypeTotals[budgetType]
    let sum
    if (key === 'debts') {
        sum = sumDebts(budget, item)
    }
    if (key === 'income' && budget[budgetType].length) {
        sum = sumItems(budget[budgetType], item)
    }
    if (key === 'expenses' && (budget.ExpensesNecessary.length || budget.ExpensesDiscretionary.length)) {
        sum = sumItems(budget.ExpensesNecessary, item)
        sum += sumItems(budget.ExpensesDiscretionary, item)
    }
    return { ...state, [key]: sum }
}

function setGroupData(state, payload) {
    const { data } = payload
    payload.data.forEach(groupItem => {
        const { attributes: { groupType } } = groupItem
        switch (groupType) {
        case BudgetItemType.Income:
            state = { ...state, Income: groupItem.id }
            break
        case BudgetItemType.Expense:
            state = { ...state, ExpensesNecessary: groupItem.id, ExpensesDiscretionary: groupItem.id }
            break
        case BudgetItemType.Debt:
            state = { ...state, Debt: groupItem.id }
            break
        }
    })
    return state
}

function setActualBudgetByMonth(state, payload) {
    const { date, data } = payload
    if (!data.length) {
        return state
    }
    let newState = state
    const dateKey = moment(date).format('YYYY_MM')
    Object.keys(state).forEach((budgetType, budgetTypeIdx) => {
        state[budgetType].forEach((item, itemIdx) => {
            data.forEach((actualItem, idx) => {
                if (item.id === actualItem.hash_id) {
                    newState = {
                        ...newState,
                        [budgetType]: Object.values({ ...newState[budgetType], [itemIdx]: { ...item, [dateKey]: actualItem.sum } }),
                    }
                }
            })
        })
    })
    return newState
}

function setActualDebtByMonth(state, payload) {
    const { date, data } = payload
    const dateKey = moment(date).format('YYYY_MM')
    if (!data.length) {
        return state
    }
    state.forEach((item, itemIdx) => {
        data.forEach((actualItem, idx) => {
            if (item.attributes.itemId === actualItem.hash_id) {
                state = Object.values({ ...state, [itemIdx]: { ...item, [dateKey]: actualItem.sum } })
            }
        })
    })
    return state
}

const setup = combineReducers({
    budget,
    groupData,
    debts,
    emergencyFund,
    finalReview,
})
export const getAuth = state => state.auth.isAuthenticated
export default setup
