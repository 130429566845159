import styled from 'styled-components'
import Text from './Text'

export const formatNumber = (value, decimals, currency) => {
    const fractionDigits = decimals ? 2 : 0
    const numberFormat = new Intl.NumberFormat('US-US', {
        ...(currency ? { style: 'currency', currency: 'USD' } : {}),
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    })
    if (isNaN(value)) return '-'
    return numberFormat.format(value)
}

const Number = styled(Text).attrs(p => ({
    children: formatNumber(p.children, p.decimals, p.currency),
}))``

Number.defaultProps = {
    decimals: false,
    currency: false,
}

export default Number
