import { lazy } from 'react'

const routes = [
    {
        path: '/setup/income',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/income')),
    },
    {
        path: '/setup/expenses-necessity',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/expenses-necessary')),
    },
    {
        path: '/setup/expenses-discretionary',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/expenses-discretionary')),
    },
    {
        path: '/setup/debts',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/debts')),
    },
    {
        path: '/setup/emergency-fund',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/emergency-fund')),
    },
    {
        path: '/setup/review',
        auth: true,
        exact: true,
        component: lazy(() => import('./pages/review')),
    },
]

export default routes
