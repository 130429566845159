import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { size, isFunction, get, reduce } from "lodash";
import getResponsive from "./getResponsive";
import { colors } from "../../vars";
import { useTranslation } from "react-i18next";
import "../../../i18next/i18next";

const Table = ({
  headers = [],
  data = [],
  keyExtractor = (item) => item.id,
  padding = 0,
  spacing = 8,
  inSection = true,
  cellBackground,
  cellColor,
  onRowClick,
  className,
  style,
  placeholder = null,
}) => {
  const colgroup = useMemo(() => {
    const hasHeaders = reduce(
      headers,
      (acc, header) => (header.width ? true : acc),
      false
    );
    if (!hasHeaders) return null;
    return (
      <colgroup>
        {headers.map(({ width = "auto" }, idx) => (
          <col key={idx} span="1" style={{ width }} />
        ))}
      </colgroup>
    );
  }, [headers]);

  const head = useMemo(() => {
    const hasLabels = reduce(
      headers,
      (acc, header) => (header.label ? true : acc),
      false
    );
    if (!hasLabels) return null;
    return (
      <thead>
        <Row>
          {headers.map((header, idx) => (
            <Header key={idx} align={header.align}>
              {header.label}
            </Header>
          ))}
        </Row>
      </thead>
    );
  }, [headers]);

  const body = useMemo(() => {
    if (size(data) < 1) return null;

    const handleRowClick = (item, rowIdx) => {
      if (isFunction(onRowClick)) {
        onRowClick(item, rowIdx);
      }
    };

    const handleCellClick = (e, header, item) => {
      if (isFunction(header.onClick)) {
        e.preventDefault();
        e.stopPropagation();
        header.onClick(item);
      }
    };

    const canClickRow = isFunction(onRowClick);

    const renderItem = (header, item) =>
      isFunction(header.render) ? header.render(item) : get(item, header.prop);

    const getCellBackground = (item) =>
      isFunction(cellBackground) ? cellBackground(item) : cellBackground;

    const getCellColor = (item) =>
      isFunction(cellColor) ? cellColor(item) : cellColor;

    return (
      <tbody>
        {data.map((item, rowIdx) => (
          <Row
            key={keyExtractor(item, rowIdx)}
            onClick={() => handleRowClick(item, rowIdx)}
            canClick={canClickRow}
          >
            {headers.map((header, cellIdx) => (
              <Cell
                key={cellIdx}
                align={header.align}
                cellBackground={getCellBackground(item)}
                cellColor={getCellColor(item)}
                canClick={isFunction(header.onClick)}
                onClick={(e) => handleCellClick(e, header, item)}
              >
                {renderItem(header, item)}
              </Cell>
            ))}
          </Row>
        ))}
      </tbody>
    );
  }, [headers, data]);

  return (
    <>
      <Container
        padding={inSection ? "30|20" : padding}
        spacing={spacing}
        className={className}
        style={style}
      >
        <StyledTable padding={inSection ? "30|20" : padding} spacing={spacing}>
          {colgroup}
          {head}
          {body}
        </StyledTable>
      </Container>
      {size(data) < 1 && placeholder && (
        <Placeholder spacing={spacing}>{placeholder}</Placeholder>
      )}
    </>
  );
};

const CELL_PADDING = 15;
const CELL_BORDER = 4;

const Container = styled.div`
  position: relative;
  width: calc(100% + ${(p) => getResponsive(p.padding * 2, 0)});
  overflow-x: auto;
  overflow-y: hidden;
  margin: -${(p) => p.spacing}px -${(p) => getResponsive(p.padding, 0)};

  @media (max-width: 600px) {
    width: calc(100% + ${(p) => getResponsive(p.padding * 2, 0, true)});
    margin: -${(p) => p.spacing}px -${(p) => getResponsive(p.padding, 0, true)};
  }
`;

const StyledTable = styled.table`
  table-layout: auto;
  white-space: nowrap;
  min-width: 100%;
  text-align: left;
  border-spacing: 0 ${(p) => p.spacing}px;
  padding: 0 ${(p) => getResponsive(p.padding, 0)};

  @media (max-width: 600px) {
    padding: 0 ${(p) => getResponsive(p.padding, 0, true)};
  }
`;

const Header = styled.th`
  font-family: "Raleway";
  font-size: 13px;
  font-weight: 600;
  color: ${colors.lightGray};
  padding: 0 5px;
  text-align: ${(p) => p.align || "left"};
  white-space: pre-wrap;

  &:first-child {
    padding-left: ${CELL_PADDING}px;
  }

  &:last-child {
    padding-right: ${CELL_PADDING}px;
  }
`;

const Row = styled.tr`
  vertical-align: ${(p) => p.align || "middle"};

  ${(p) =>
    p.canClick &&
    css`
      &:hover {
        opacity: 0.9;
      }

      td {
        cursor: pointer;
      }
    `}
`;

const Placeholder = styled.div`
  background-color: ${(p) => colors.rgba(colors.gray, 0.25)};
  border-radius: ${CELL_BORDER}px;
  padding: 0 ${CELL_PADDING}px;
  margin-top: ${(p) => p.spacing}px;
  height: 35px;
  line-height: 35px;
  color: ${colors.white};
`;

const Cell = styled.td`
  background-color: ${(p) =>
    p.cellBackground || colors.rgba(colors.gray, 0.75)};
  color: ${(p) => p.cellColor || colors.white};
  padding: 0 ${CELL_PADDING / 2}px;
  height: 35px;
  vertical-align: middle;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(p) => p.align || "left"};
  ${(p) =>
    p.canClick &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}

  .text {
    color: inherit;
    font: inherit;
    text-align: inherit;
  }

  &:first-child {
    padding-left: ${CELL_PADDING}px;
    border-top-left-radius: ${CELL_BORDER}px;
    border-bottom-left-radius: ${CELL_BORDER}px;
  }

  &:last-child {
    padding-right: ${CELL_PADDING}px;
    border-top-right-radius: ${CELL_BORDER}px;
    border-bottom-right-radius: ${CELL_BORDER}px;
  }
`;

export default Table;
