import React from "react";
import { createGlobalStyle } from "styled-components";
import { colors } from "../vars";
import { useSelector } from "react-redux";

export const Styles = (props) => {
  const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      font: inherit;
      line-height: inherit;
      color: inherit;
      cursor: inherit;
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    *:focus {
      outline: none;
    }

    html {
      overflow: hidden;
      background: ${colors.background};
      height: 100%;
    }

    body {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      overflow: hidden;
      min-width: 375px;
      width: 100%;
      font-size: 15px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.25;
      background: ${colors.background};
      color: ${colors.gray};
      min-height: 100%;
      cursor: default;
    }

    body a {
      color: inherit;
    }

    body button {
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: transparent;
      border-radius: 0;
      font: inherit;
    }

    body input,
    body textarea {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -moz-appearance: none;
      -webkit-appearance: none;
      display: block;
      width: 100%;
      background: transparent;
    }

    body input::placeholder,
    body textarea::placeholder {
      color: inherit;
      opacity: 1;
    }

    ul,
    ol {
      list-style: none;
    }

    a,
    button {
      cursor: pointer;
    }

    p {
      font-family: 'Roboto', sans-serif;
    }

    input,
    textarea {
      cursor: text;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    img,
    svg {
      display: block;
      overflow: visible;
    }

    b,
    strong {
      font-weight: 700;
    }

    q:before,
    q:after {
      content: unset
    }

    ::selection {
      background-color: ${colors.lightGray};
      color: ${colors.white};
    }
    

  `;

  return { GlobalStyle };
};
