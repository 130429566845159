const toRGB = (hex) => {
  if (!hex) return "transparent";
  const c = parseInt(hex.replace("#", ""), 16);
  return `${(c >> 16) & 255},${(c >> 8) & 255},${c & 255}`; // eslint-disable-line no-bitwise
};

const colors = {
  gold: "#AD9254",
  background: "#ffffff",
  darkBackground: "#efeff1",
  darkGray: "#2B2B2B",
  gray: "#585B6A",
  lightGray: "#A5A5A5",
  white: "#ffffff",
  black: "#000000",
  red: "#A55752",
  purple: "#54516C",

  transparent: "transparent",
  inherit: "inherit",

  rgba: (color, opacity) => `rgba(${toRGB(color)}, ${opacity})`,
};

export default colors;
