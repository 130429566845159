import React, { useRef, useState, useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";
import UIDropdown from "./Dropdown";
import Input from "./Input";
import Text from "./Text";
import Icon from "./Icon";
import { colors } from "../../vars";
import { useHistory } from "react-router-dom";

const getGroups = (options) =>
  _.isPlainObject(options) ? _.keys(options) : null;

const Select = ({
  translateSelector,
  value,
  onChange = () => {},
  options = [],
  placeholder,
  stretchedDropdown,
  border,
  align,
  size,
  width,
  className,
  style,
}) => {
  const history = useHistory();
  const dropdownRef = useRef();
  const [focused, setFocused] = useState(false);
  const [group, setGroup] = useState(_.get(getGroups(options), "[0]"));

  const handleOptionClick = (option) => {
    onChange(option);
    dropdownRef.current.close();
  };

  const optionsGroups = useMemo(() => getGroups(options), [options]);

  return (
    <Dropdown
      trigger={
        <>
          <Input
            size={size}
            value={value}
            align={align}
            onChange={() => {}}
            placeholder={placeholder}
            focused={focused}
            border={border}
            disabled
            className="select-input"
          />
          <CaretDown className="select-caret" />
        </>
      }
      stretched={stretchedDropdown}
      alignDropdownRight={align === "right"}
      hasCaret
      size={size}
      ref={dropdownRef}
      width={width}
      className={className}
      style={style}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {optionsGroups ? (
        <>
          <Categories>
            {optionsGroups.map((name) => (
              <Text
                as="button"
                font="raleway"
                size="9"
                key={name}
                color={name === group ? "gray" : "lightGray"}
                // onClick={() => setGroup(name)} // TODO revisit the functionality
                onClick={() => history.push("/dashboard/home")}
              >
                {name}
              </Text>
            ))}
          </Categories>
          {options[group].map((option) => (
            <Option key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </Option>
          ))}
        </>
      ) : (
        options.map((option) => (
          <Option key={option} onClick={() => handleOptionClick(option)}>
            {option}
          </Option>
        ))
      )}
    </Dropdown>
  );
};

const caretPos = {
  top: { s: 7, m: 9, l: 12 },
  right: { s: 5, m: 7, l: 9 },
  padding: { s: 14, m: 20, l: 26 },
};

const Dropdown = styled(UIDropdown)`
  .select-caret {
    top: ${(p) => caretPos.top[p.size] || caretPos.top.m}px;
    right: ${(p) => caretPos.right[p.size] || caretPos.right.m}px;
  }

  .select-input {
    padding-right: ${(p) => caretPos.padding[p.size] || caretPos.padding.m}px;
  }

  &.opened .select-caret {
    transform: rotate(180deg);
  }
`;

const Categories = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 5px;
  margin: 0 10px 5px;
  border-bottom: 1px solid ${colors.lightGray};

  ${Text} + ${Text} {
    &:before {
      content: "|";
      display: inline-block;
      margin: 0 5px;
    }
  }
`;

const CaretDown = styled(Icon).attrs({
  name: "caret-down",
  size: 10,
  color: "gray",
})`
  position: absolute;
  right: 7px;
  top: 10px;
`;

const Option = styled(Text).attrs({ size: 15, weight: 500, align: "left" })`
  padding: 2px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.rgba(colors.black, 0.05)};
  }
`;

export default Select;
