import styled from 'styled-components'
import { colors } from '../../vars'

const Info = styled.button.attrs(p => ({
    children: p.type,
}))`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    border-radius: 50%;
    font-family: 'Raleway', sans-serif;
    font-size: ${p => p.size * 0.75}px;
    background-color: ${colors.rgba(colors.lightGray, 0.3)};
    border: 1px solid ${colors.lightGray};
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    color: ${colors.gray};
    font-weight: 600;
    top: -0.125em;
    align-items: center;
    justify-content: center;
    margin: ${p => p.margin === 'left' ? '0 0 0 5px' : p.margin === 'right' ? '0 5px 0 0' : 0};
    line-height: 1em;
`

Info.defaultProps = {
    type: '?',
    margin: 'left',
    size: 20,
}

export default Info
