import styled from 'styled-components'
import moment from 'moment'
import Text from './Text'

const formatDate = (value, format) => {
    const date = moment.utc(value || '-')
    return date.isValid() ? date.format(format) : '-'
}

const DateTime = styled(Text).attrs(p => ({
    children: formatDate(p.children, p.format),
}))``

DateTime.defaultProps = {
    format: 'MMMM DD, YYYY',
}

export default DateTime
