import HTTP from "../../../utils/Http";
import {
  AUTH_CHECK,
  AUTH_LOGOUT,
  CHECK_COMPLETED_WIZARD,
  GET_USER_PROFILE,
  SEND_EMAIL,
  IS_AUTH,
  IS_NAVIGATE,
  IS_AVATAR,
  IS_CURRENCY,
  IS_LANGUAGE,
  IS_LIGHT_DARK, IS_PRODUCTS,
} from "./action-types";
// import { useState } from "react";
// import { isAuth } from "./actions";

const initialState = {
  isAuthenticated: false,
  isCompletedWizard: "loading",
  userProfile: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  email: "",
  isAuth: localStorage.getItem("item")
    ? JSON.parse(localStorage.getItem("item"))
    : false,
  DarkLight: localStorage.getItem("dark_light")
    ? JSON.parse(localStorage.getItem("dark_light"))
    : false,
  isNavigate: localStorage.getItem("boolean")
    ? JSON.parse(localStorage.getItem("boolean"))
    : false,
  isAvatar: localStorage.getItem("avatar")
    ? JSON.parse(localStorage.getItem("avatar"))
    : "",
  isCurrency: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : "$",
  isLanguage: localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language"))
    : "en",
  isProducts:localStorage.getItem("products")
      ? JSON.parse(localStorage.getItem("products"))
      : {},
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case CHECK_COMPLETED_WIZARD:
      return Object.assign({}, state, { isCompletedWizard: payload });
    case GET_USER_PROFILE:
      return Object.assign({}, { ...state, userProfile: payload });
    case SEND_EMAIL:
      return { ...state, email: payload };
    case IS_AUTH:
      return { ...state, isAuth: payload };
    case IS_LIGHT_DARK:
      return { ...state, DarkLight: payload };
    case IS_NAVIGATE:
      return { ...state, isNavigate: payload };
    case IS_AVATAR:
      return { ...state, isAvatar: payload };
    case IS_CURRENCY:
      return { ...state, isCurrency: payload };
    case IS_LANGUAGE:
      return { ...state, isLanguage: payload };
    case IS_PRODUCTS:
      return Object.assign({},{...state,isProducts: payload})
    default:
      return state;
  }
};

function checkAuth(state) {
  const env = process.env.NODE_ENV;
  if (env && env !== "production") {
    const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
    if (accessToken) {
      localStorage.setItem("access_token", accessToken);
    }
  }
  state = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem("access_token"),
  });

  if (state.isAuthenticated) {
    HTTP.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return state;
}

function logout(state) {
  localStorage.removeItem("access_token");

  return {
    ...state,
    isAuthenticated: false,
  };
}

export const getAuth = (state) => state.auth.isAuthenticated;
export const getCompletedWizard = (state) => state.auth.isCompletedWizard;

export default reducer;
