import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, mixins } from '../../vars'
import Icon from '../ui/Icon'
import actions from './store/actions'

const ModalWrapper = () => {
    const state = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const { component: Component, props, options, opened } = state

    if (!Component || !opened) return null

    const { clickToClose, closeButton, width } = (options || {})

    const handleCloseOutside = clickToClose ? () => dispatch(actions.close()) : () => {}
    const handleCloseButton = () => dispatch(actions.close())

    return (
        <Container className="main-modal-container">
            <CloseOutside onClick={handleCloseOutside} />
            <Window width={width}>
                {closeButton && <Close onClick={handleCloseButton} />}
                <Component {...props} />
            </Window>
        </Container>
    )
}

const Container = styled.div`
    ${mixins.inset()}
    -webkit-overflow-scrolling: touch;
    position: fixed;
    background: ${colors.rgba(colors.black, 0.25)};
    z-index: 200;
    vertical-align: middle;
    font-size: 0;
    overflow-y: auto;
    text-align: center;
    white-space: nowrap;
    visibility: visible;
    opacity: 1;
    pointer-events: all;

    &:after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0;
    }
`

const Window = styled.div`
    position: relative;
    white-space: normal;
    z-index: 2;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background: ${colors.white};
    font-size: 15px;
    margin: 20px;
    width: calc(100% - 40px);
    max-width: ${p => p.width}px;
    box-shadow: 4px 4px 10px rgba(52, 52, 52, 0.25);
    padding: 30px;
    border-radius: 11px;

    @media (max-width: 600px) {
        padding: 20px;
    }
`

const Close = styled.button.attrs({
    children: <Icon name="times" size="18" color="lightGray" />,
})`
    position: absolute;
    top: 15px;
    right: 15px;

    &:after {
        ${mixins.inset('-5px')}
        position: absolute;
        content: '';
    }
`

const CloseOutside = styled.div`
    ${mixins.inset()}
    position: absolute;
    z-index: 1;
`

export default ModalWrapper
