import React from "react";
import PropTypes from "prop-types";
import Layout from "../common/Layout";

const PrivateLayout = ({ children, location }) => {
  const showNavigation = location.pathname.includes("dashboard");

  return <Layout>{children}</Layout>;
};

PrivateLayout.dispatch = "Private Layout";
PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;
