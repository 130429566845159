import styled from 'styled-components'
import getResponsive from './getResponsive'
import { colors } from '../../vars'

const Section = styled.div`
    background-color: ${colors.white};
    box-shadow: ${p => p.shadow ? `0 4px 10px ${colors.rgba(colors.black, 0.1)}` : 'none'};
    border-radius: 11px;
    padding: ${p => getResponsive(p.padding, '30px')};
    overflow: ${p => p.overflowHidden ? 'hidden' : 'visible'};

    @media (max-width: 600px) {
        padding: ${p => getResponsive(p.padding, '20px', true)};
        ${p => p.disableMobile && `
            padding: 0;
            background-color: ${colors.transparent};
            box-shadow: none;
            border-radius: 0;
        `}
    }
`

Section.defaultProps = {
    disableMobile: true,
}

export default Section
