import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Spinner from './Spinner'
import { colors, mixins } from '../../vars'

const ButtonSpinner = styled(Spinner).attrs({ size: 18 })`
    position: absolute;
    left: calc(100% + 7px);
    top: 12px;
`

const Button = styled.button.attrs(p => ({
    as: p.to ? Link : p.as ? p.as : 'button',
    children: (
        <span>
            {p.children}
            {p.spinner && <ButtonSpinner />}
        </span>
    ),
}))`
    position: relative;
    max-width: ${p => p.width || '275px'};
    width: 100%;
    margin: 0 auto;
    background-color: ${p => colors[p.color] || p.color || colors.gray};
    color: ${colors.white};
    border-radius: 11px;
    text-transform: uppercase;
    display: table;
    line-height: 44px;
    height: 44px;
    padding: 0 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    visibility: ${p => p.hidden ? 'hidden' : 'visible'};
    ${p => p.disabled && `
        pointer-events: none;
        opacity: 0.5;
    `}

    &:hover {
        background-color: ${p => colors.rgba(colors[p.color] || p.color || colors.gray, 0.75)};
    }

    span {
        position: relative;
        display: inline-block;
        transform: ${p => p.spinner ? 'translateX(-10px)' : 'none'};
        ${mixins.transition('transform')}
    }
`

Button.defaultProps = {
    className: 'button',
}

export default Button
