import React from 'react'
import styled from 'styled-components'
import { colors } from '../../vars'
import getResponsive from './getResponsive'

const Icon = ({
    size = null,
    name = null,
    color = null,
    inline = false,
    className = '',
    style,
    ...props
}) => {
    let svg

    try {
        svg = require(`!!svg-loader!../../assets/icons/${name}.svg`)
    } catch(e) {
        console.error('Icon not found.', name)
        return null
    }
    if (!svg.attributes){
        return null;
    }
    const svgSize = svg.attributes.viewBox.split(' ')
    const ratio = svgSize[2] / svgSize[3]

    return (
        <SVG
            className={`icon icon--${name} ${className}`}
            style={style}
            fill="none"
            viewBox={svg.attributes.viewBox}
            xmlns="http://www.w3.org/2000/svg"
            inline={inline}
            ratio={ratio}
            size={size}
            color={colors[color] || color}
            dangerouslySetInnerHTML={{ __html: svg.content }}
            {...props}
        />
    )
}

const SVG = styled.svg`
    height: 1em;
    display: block;
    color: ${p => p.color || 'currentColor'};
    font-size: ${p => getResponsive(p.size, 'inherit')};
    width: ${p => `${p.ratio}em`};

    ${p => p.inline && `
        display: inline-block;
        vertical-align: -0.125em;
    `};

    @media (max-width: 600px) {
        font-size: ${p => getResponsive(p.size, 'inherit', true)};
    }
`

export default React.memo(Icon)
