import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import Flipsnap from 'flipsnap'
import _ from 'lodash'
import Flex from './Flex'
import { colors, mixins } from '../../vars'

const Slider = ({
    children,
    controls = true,
    style,
    className,
}) => {
    const [active, setActive] = useState(0)
    const slidesRef = useRef()
    const flipsnapRef = useRef()

    const updateState = useCallback(() => {
        const fs = flipsnapRef.current
        setActive(fs.currentPoint)
    }, [])

    const refreshFlipsnap = useCallback(() => {
        const fs = flipsnapRef.current
        fs.distance = fs._distance = fs.element.childNodes[1]?.offsetLeft || 0
        fs.refresh()
    }, [])

    useEffect(() => {
        const fs = flipsnapRef.current = new Flipsnap(slidesRef.current)
        const debouncedRefresh = _.debounce(refreshFlipsnap, 50, { maxWait: 200 })
        fs.element.addEventListener('fspointmove', updateState)
        window.addEventListener('resize', debouncedRefresh)
        updateState()
        refreshFlipsnap()

        return () => {
            window.removeEventListener('resize', debouncedRefresh)
        }
    }, [])

    useEffect(() => {
        const fs = flipsnapRef.current
        if (fs.currentPoint !== active) fs.moveToPoint(active)
    }, [active])

    const slidesLength = React.Children.toArray(children).length

    return (
        <div style={style} className={className}>
            <Slides active={active} ref={slidesRef}>
                {children}
            </Slides>
            {(controls && slidesLength > 1) && (
                <Controls>
                    {Array.from({ length: slidesLength }, (_, idx) => (
                        <Dot
                            active={active === idx}
                            onClick={() => setActive(idx)}
                            key={idx}
                        />
                    ))}
                </Controls>
            )}
        </div>
    )
}

const Slides = styled(Flex).attrs({ align: 'stretch' })`
    width: 100%;
    position: relative;
`

const Slide = styled.div`
    flex: 1 0 auto;
    width: 100%;
    min-height: 0;
    margin-right: 30px;
`

const Controls = styled(Flex).attrs({ align: 'center', justify: 'center' })`
    padding-top: 25px;
`

const Dot = styled.button`
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 0 4px;
    background-color: ${p => colors[p.active ? 'gold' : 'lightGray']};

    &:before {
        content: '';
        position: absolute;
        ${mixins.inset('-10px -4px')}
    }
`

Slider.Slide = Slide

export default Slider
