export const ANIMATION_DURATION = 300
export const ANIMATION_EASING = 'ease-out'

const mixins = {
    transition: (values = '', duration = ANIMATION_DURATION, easing = ANIMATION_EASING) => {
        const mapProperty = value => `${value} ${duration}ms ${easing}`
        const properties = values.split(' ').map(mapProperty).join(', ')
        return `transition: ${properties};`
    },
    inset: (value = '0') => {
        const v = value.split(' ')
        var l = v.length
        return `
            top: ${v[0]};
            right: ${l < 2 ? v[0] : v[1]};
            bottom: ${l < 3 ? v[0] : v[2]};
            left: ${l < 2 ? v[0] : l < 4 ? v[1] : v[3]};
        `
    },
}

export default mixins
